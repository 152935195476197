// DebouncedButton.js
import React from "react";
import { Button } from "antd"; // Importing Button from a UI library
import useDebounceClick from "utils/hooks/useDebounceClick";

const DebouncedButton = ({ onClick, delay = 2000, children, ...props }) => {
  const debouncedOnClick = useDebounceClick(onClick, delay);

  return (
    <Button onClick={debouncedOnClick} {...props}>
      {children}
    </Button>
  );
};

export default DebouncedButton;
