import { Card, Table } from "antd";
import React from "react";
import "./kiranaReceipt.css"; // Assuming you have a CSS file for styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faUser,
  faEnvelope,
  faPhone,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

const KiranaReceipt = (props) => {
  const { getTableData } = props;
  const formatDate = (isoString) => {
    const date = new Date(isoString);

    const formattedDate = date.toLocaleString("en-IN", {
      weekday: "short", // e.g., 'Mon'
      year: "numeric", // e.g., '2024'
      month: "short", // e.g., 'Nov'
      day: "numeric", // e.g., '13'
      hour: "2-digit", // e.g., '12'
      minute: "2-digit", // e.g., '36'
      second: "2-digit", // e.g., '44'
      hour12: true, // 12-hour format (AM/PM)
    });

    return formattedDate;
  };

  const formattedDate = formatDate(getTableData.created_at);

  const receiptData = {
    customerName: `${getTableData.name}`,
    amountPaid: `Rs. ${getTableData.txn_amount}`,
    transactionId: getTableData.txn_id,
    transactionDate: `${formattedDate}`,
    paymentMode: "Online Payment",
    email: `${getTableData.email}`,
    mobileNumber: `${getTableData.mobile}`,
    contact: `${getTableData.mobile}`,
  };
  const ownerData = {
    contact: "1800-220-233",
    website: "general.futuregenerali.in",
    email: "fgcare@futuregenerali.in",
  };

  const notoFontStyle = {
    fontFamily: "Noto, serif",
  };

  return (
    <>
      <div className="receipt-body ">
        <div className="receipt-container">
          <div className="header">
            <img src="/brand-logo.png" alt="Company Logo" />
          </div>

          <p className="customer-name" style={notoFontStyle}>
            Dear <span>{receiptData.customerName}</span>,
          </p>

          <div className="success-section">
            <div className="check-icon">
              <FontAwesomeIcon icon={faCheck} />
            </div>
            <p className="payment-success mb-0" style={notoFontStyle}>
              Your payment for <strong>{receiptData.amountPaid}</strong> is
              Successful
            </p>
          </div>

          <div className="welcome-message" style={notoFontStyle}>
            <p>Welcome to the Future Generali Experience!</p>
            <p>
              Our initiatives provide you with the highest standards of service,
              convenience, and quality in insurance and it is our endeavour to
              constantly better your experience by innovating and evolving our
              basket of conveniences.
            </p>
          </div>

          <p className="note" style={notoFontStyle}>
            <strong>Note:</strong> A successful transaction does not indicate
            acceptance of risk.
          </p>

          <div className="section-content">
            <h3 className="section-header" style={notoFontStyle}>
              Transaction Details
            </h3>
            <div className="transaction-row">
              <div className="label" style={notoFontStyle}>
                Transaction ID
              </div>
              <div className="value" style={notoFontStyle}>
                {receiptData.transactionId}
              </div>
            </div>
            <div className="transaction-row">
              <div className="label" style={notoFontStyle}>
                Transaction Date
              </div>
              <div className="value" style={notoFontStyle}>
                {receiptData.transactionDate}
              </div>
            </div>
            <div className="transaction-row">
              <div className="label" style={notoFontStyle}>
                Amount Paid
              </div>
              <div className="value" style={notoFontStyle}>
                {receiptData.amountPaid}
              </div>
            </div>
            <div className="transaction-row">
              <div className="label" style={notoFontStyle}>
                Payment Mode
              </div>
              <div className="value" style={notoFontStyle}>
                {receiptData.paymentMode}
              </div>
            </div>
          </div>

          <div className="customer-details">
            <h3 className="section-title" style={notoFontStyle}>
              Customer Details
            </h3>
            <div className="customer-row">
              <div className="icon">
                <FontAwesomeIcon icon={faUser} />
              </div>
              <div className="label" style={notoFontStyle}>
                Customer Name
              </div>
              <div className="value" style={notoFontStyle}>
                {receiptData.customerName}
              </div>
            </div>
            <div className="customer-row">
              <div className="icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              <div className="label" style={notoFontStyle}>
                Email Address
              </div>
              <div className="value" style={notoFontStyle}>
                {receiptData.email}
              </div>
            </div>
            <div className="customer-row">
              <div className="icon">
                <FontAwesomeIcon icon={faPhone} />
              </div>
              <div className="label" style={notoFontStyle}>
                Mobile Number
              </div>
              <div className="value" style={notoFontStyle}>
                {receiptData.mobileNumber}
              </div>
            </div>
          </div>

          {/* Footer Section */}
        </div>
        <div className="footer">
          <div className="contact">
            <FontAwesomeIcon icon={faPhone} className="icon" />
            <span style={notoFontStyle}>{ownerData.contact}</span>
          </div>

          <div className="email">
            <FontAwesomeIcon icon={faEnvelope} className="icon" />
            <a style={notoFontStyle} href={`mailto:${ownerData.email}`}>
              {ownerData.email}
            </a>
          </div>

          <div className="website">
            <FontAwesomeIcon icon={faGlobe} className="icon" />
            <a
              style={notoFontStyle}
              href={ownerData.website}
              target="_blank"
              rel="noopener noreferrer"
            >
              {ownerData.website}
            </a>
          </div>
        </div>
      </div>
      <div className="p-2">
        <p className="title-wish mt-4" style={notoFontStyle}>
          With Best Regards ,
        </p>
        <p className="description" style={notoFontStyle}>
          Future Generali India Insurance Co Ltd., Unit 801 and 802 , 8th floor,
          Tower C, Embassy 247 Park , L.B.S. Marg, Vikhroli (W), Mumbai - 400
          083 . Website : https ://general .futuregenerali .in | Email: fgcare
          @futuregenerali .in | Call us at: 1800 -220 -233 / 1860 -500 -3333 /
          022 - 67837800 | Fax No: 022 4097 6900 . Trade Logo displayed above
          belongs to M/S Assicurazioni Generali - Societa Per Azioni and used by
          Future Generali India Insurance Company Limited under License .
        </p>
      </div>
    </>
  );
};

export default KiranaReceipt;
