import React, { useRef } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useLocation, useNavigate } from "react-router-dom";
import KiranaReceipt from "views/app-views/info/PolicyDetails/kiranaReceipt";
import html2pdf from "html2pdf.js";
import DebouncedButton from "components/DebouncedButton/DebouncedButton";
import {
  EditOutlined,
  DownloadOutlined,
  FilePdfOutlined,
  SearchOutlined,
  FilterOutlined,
  ExportOutlined,
} from "@ant-design/icons";

const PaymentSuccessReceipt = () => {
  const location = useLocation();
  const { policyData, paymentData } = location.state || {};

  const receiptRef = useRef(null);
  const navigate = useNavigate();

  if( !policyData || !paymentData){
    setTimeout(() => {
      navigate('/app/info/Policy');
    }, 100);
    return null;
  }

  let receiptData ={
    name : policyData.name,
    created_at : new Date(policyData.created_at).toISOString().split("T")[0],
    txn_amount : paymentData.amount / 100,
    txn_id : paymentData.id,
    email : policyData.email ,
    mobile : policyData.mobile ,
  }
  const downloadReceipt = async (uniqueId) => {
    const element = receiptRef.current;
    if (!element) return;
    const opt = {
      margin: 0.1,
      filename: `${uniqueId}_Receipt`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 4 },
      jsPDF: { unit: "in", format: [9, 16], orientation: "portrait" },
    };
    html2pdf().from(element).set(opt).save();
    // const canvas = await html2canvas(element);
    // const imgData = canvas.toDataURL("image/png");
    // const pdf = new jsPDF();
    // pdf.addImage(imgData, "PNG", 10, 10, 180, 0);
    // pdf.save("receipt.pdf");
  };

  return (
    <div>
      <h2>Payment Successful</h2>

      {/* Receipt Preview */}
      <div ref={receiptRef} style={{ padding: "20px", border: "1px solid #ccc" }}>
        <KiranaReceipt getTableData={receiptData}
								getpackageID={policyData.package_name}
                />
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "0px", marginTop: "10px" }}>
        <div style={{ width: 120 }}>
          <DebouncedButton
            className="button-receipt text-dark"
            onClick={async () => {
              try {
                downloadReceipt(policyData.policy_no);
              } catch (error) {
                console.error("Error in downloading the certificate:", error);
              }
            }}
          >
            <DownloadOutlined />
            Receipt
          </DebouncedButton>
        </div>
        <div style={{ width: 120 }}>
          <DebouncedButton
            className="button-receipt text-dark"
            style={{
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
            }}
            onClick={() => navigate("/app/info/PolicyDetails")}
          >
            Go to Reports
          </DebouncedButton>
        </div>
    </div>
    </div>
  );
};

export default PaymentSuccessReceipt;
