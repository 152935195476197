// useDebounceClick.js
import { useCallback, useRef, useEffect } from "react";

const useDebounceClick = (callback, cooldownDuration = 2000) => {
  const cooldownRef = useRef(false); // Track if the cooldown is active
  const timerRef = useRef(null); // Reference for the timer

  const debouncedFunction = useCallback(
    (...args) => {
      if (!cooldownRef.current) {
        // Execute the callback if the cooldown is not active
        callback(...args);
        cooldownRef.current = true; // Set cooldown to active

        // Set a timer to reset the cooldown
        timerRef.current = setTimeout(() => {
          cooldownRef.current = false; // Reset the cooldown after the specified duration
        }, cooldownDuration);
      }
    },
    [callback, cooldownDuration]
  );

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current); // Clear the timer if the component unmounts
    };
  }, []);

  return debouncedFunction;
};

export default useDebounceClick;
